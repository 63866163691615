import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import CaseStudyHeader from '../components/case-study-header'
import ShowcaseImage from '../components/showcase-image'
import Features from '../components/features'
import TextBlockWithImage from '../components/text-block-with-image'
import SpacerRegular from '../components/spacer-regular'
import SpacerLarge from '../components/spacer-large'

class CaseStudyMoneySuperMarket extends React.Component {
  render() {
    const [header] = get(this, 'props.data.header.nodes')
    const [showcaseImage] = get(this, 'props.data.showcaseImage.nodes')
    const [statistics] = get(this, 'props.data.statistics.nodes')
    const [textBlockOne] = get(this, 'props.data.textBlockOne.nodes')
    const [textBlockTwo] = get(this, 'props.data.textBlockTwo.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Case Study: MoneySuperMarket" />
        <CaseStudyHeader
          title={header.title}
          subtitle={header.subtitle}
          description={header.description.description}
        />
        <ShowcaseImage image={showcaseImage.image}/>
        <SpacerRegular/>
        <Features features={statistics}/>
        <SpacerRegular/>
        <TextBlockWithImage
          title={textBlockOne.title}
          subtitle={textBlockOne.subtitle.subtitle}
          image={textBlockOne.image}
          leftImage={textBlockOne.leftImage}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={textBlockTwo.title}
          subtitle={textBlockTwo.subtitle.subtitle}
          image={textBlockTwo.image}
          leftImage={textBlockTwo.leftImage}
        />
        <SpacerLarge/>
      </Layout>
    )
  }
}

export default CaseStudyMoneySuperMarket

export const pageQuery = graphql`
  query CaseStudyMoneySuperMarketQuery {
    header: allContentfulHeader(
      filter: { contentful_id: { eq: "3B27WCKzT8IQuq1fcfqZYb" } }
    ) {
      nodes {
        title
        subtitle
        description {
          description
        }
      }
    }
    showcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "4PPjj006MRXGZPUwSAGWms" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    statistics: allContentfulDataSet(
      filter: { contentful_id: { eq: "2p74YTCFUv5kQicCHim042" } }
    ) {
      nodes {
        dataOneTitle
        dataOneSubtitle
        dataTwoTitle
        dataTwoSubtitle
        dataThreeTitle
        dataThreeSubtitle
      }
    }
    textBlockOne: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "31LWjm08YvlDEed2Xa0OHg" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    textBlockTwo: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "6h1pVs32cH0emPdfs32ftK" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
  }
`
